// data:image/svg+xml;charset=utf8,

.icon {
  box-sizing: border-box;
  display: inline-block;
  width: 32px;
  height: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 60%;
  transition: .2s;

  border: 1px solid $c-main;
  border-radius: 50%;
  padding: 2px;

  &:hover {
    background-color: rgba($c-accent1, .2);
  }

  @include bp($md) {
    width: 16px;
    height: 16px;
    padding: 0;
    background-size: 100%;
    border: 0;
    border-radius: 0;

    &:hover {
      background-color: transparent;
    }
  }

  &.active {
    background-color: rgba($c-accent1, .2);

    @include bp($md) {
      background-color: transparent;
    }
  }

  &_add {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16"><path d="M8,0.732A7.268,7.268,0,1,0,15.268,8,7.289,7.289,0,0,0,8,.732Zm3.634,7.994H8.727v2.907H7.273V8.727H4.366V7.273H7.273V4.366H8.727V7.273h2.907V8.727Z"/></svg>');
  }
  &_bell {
    background-position: center 40%;
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18"><path d="M14.929,15.176L13.64,13.032a6.362,6.362,0,0,1-.909-3.277V7.876A5.253,5.253,0,0,0,8.979,2.852V1.507a1.5,1.5,0,0,0-3,0V2.852A5.253,5.253,0,0,0,2.226,7.876V9.754a6.365,6.365,0,0,1-.909,3.276L0.029,15.175a0.376,0.376,0,0,0,.321.568H14.607A0.375,0.375,0,0,0,14.929,15.176ZM5.115,16.493a2.612,2.612,0,0,0,4.726,0H5.115Z"/></svg>');
    
    &.active {
      background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="rgb(184,53,57)" width="15" height="18" viewBox="0 0 15 18"><path d="M14.929,15.176L13.64,13.032a6.362,6.362,0,0,1-.909-3.277V7.876A5.253,5.253,0,0,0,8.979,2.852V1.507a1.5,1.5,0,0,0-3,0V2.852A5.253,5.253,0,0,0,2.226,7.876V9.754a6.365,6.365,0,0,1-.909,3.276L0.029,15.175a0.376,0.376,0,0,0,.321.568H14.607A0.375,0.375,0,0,0,14.929,15.176ZM5.115,16.493a2.612,2.612,0,0,0,4.726,0H5.115Z"/></svg>');
    }
  }
  &_case {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13"><path d="M9.4,8.94a0.55,0.55,0,0,1-.542.542H6.144A0.55,0.55,0,0,1,5.6,8.94V7.585H-0.092v4.067a1.305,1.305,0,0,0,.4.957,1.306,1.306,0,0,0,.958.4H13.736a1.359,1.359,0,0,0,1.356-1.356V7.585H9.4V8.94h0ZM6.415,7.585H8.585V8.669H6.415V7.585ZM14.694,2.56a1.306,1.306,0,0,0-.958-0.4H10.754V0.806A0.784,0.784,0,0,0,10.516.23,0.784,0.784,0,0,0,9.94-.007H5.06A0.785,0.785,0,0,0,4.484.23a0.784,0.784,0,0,0-.237.576V2.162H1.264a1.306,1.306,0,0,0-.958.4,1.305,1.305,0,0,0-.4.957V6.771H15.092V3.517A1.305,1.305,0,0,0,14.694,2.56Zm-5.025-.4H5.331V1.077H9.669V2.162Z"/></svg>');
  }
  &_envelope {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"><path d="M5.922,6.58l-5.943,5.094A1.211,1.211,0,0,0,.808,12H15.192a1.206,1.206,0,0,0,.826-0.326L10.079,6.58,8,8.4ZM15.192,0H0.808A1.205,1.205,0,0,0-.019.327L8,7.2l8.02-6.875A1.209,1.209,0,0,0,15.192,0ZM-0.4,11.022L5.4,6.094-0.4,1.053v9.969Zm16.8,0V1.05L10.6,6.094Z"/></svg>');
    
    &.active {
      background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="rgb(184,53,57)" width="16" height="12" viewBox="0 0 16 12"><path d="M5.922,6.58l-5.943,5.094A1.211,1.211,0,0,0,.808,12H15.192a1.206,1.206,0,0,0,.826-0.326L10.079,6.58,8,8.4ZM15.192,0H0.808A1.205,1.205,0,0,0-.019.327L8,7.2l8.02-6.875A1.209,1.209,0,0,0,15.192,0ZM-0.4,11.022L5.4,6.094-0.4,1.053v9.969Zm16.8,0V1.05L10.6,6.094Z"/></svg>');
    }
  }
  &_favorites {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14"><path d="M7.854,0.208L9.762,4.632l4.884,0.411a0.376,0.376,0,0,1,.219.661l-3.7,3.145,1.11,4.678a0.383,0.383,0,0,1-.574.408l-4.2-2.48-4.2,2.48a0.383,0.383,0,0,1-.574-0.408l1.11-4.678L0.134,5.7A0.376,0.376,0,0,1,.354,5.042l4.884-.411L7.145,0.208A0.388,0.388,0,0,1,7.854.208Z"/></svg>');
  }
  &_folder {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11"><path d="M3.976,5.366a3.833,3.833,0,0,1,1.6-.387H12.36v-1.3a1.757,1.757,0,0,0-.538-1.287,1.756,1.756,0,0,0-1.287-.538H6.1V1.589A1.756,1.756,0,0,0,5.565.3,1.757,1.757,0,0,0,4.278-.236H1.671A1.756,1.756,0,0,0,.383.3,1.756,1.756,0,0,0-.154,1.589V9.411q0,0.033,0,.1t0,0.1l0.041-.049L2.64,6.339A3.8,3.8,0,0,1,3.976,5.366ZM14.938,6.127a1.1,1.1,0,0,0-.493-0.106H5.581a2.827,2.827,0,0,0-1.169.289,2.762,2.762,0,0,0-.982.7L0.693,10.242a0.839,0.839,0,0,0-.253.538,0.362,0.362,0,0,0,.216.35,1.1,1.1,0,0,0,.493.106h8.864a2.829,2.829,0,0,0,1.169-.289,2.762,2.762,0,0,0,.982-0.7L14.9,7.015a0.838,0.838,0,0,0,.252-0.538A0.362,0.362,0,0,0,14.938,6.127Z"/></svg>');
  }
  &_login {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13"><path d="M7.5,0A7.662,7.662,0,0,0,0,7.82a7.99,7.99,0,0,0,1.7,4.956C1.866,11.409,3.176,10.252,5.014,9.7a3.474,3.474,0,0,0,4.926.047c1.853,0.6,3.135,1.82,3.177,3.244A7.978,7.978,0,0,0,15,7.82,7.662,7.662,0,0,0,7.5,0Zm0,9.824a2.593,2.593,0,0,1-1.33-.375,3.574,3.574,0,0,1-1.629-3.1A3.25,3.25,0,0,1,7.5,2.883a3.25,3.25,0,0,1,2.959,3.472A3.561,3.561,0,0,1,8.785,9.477,2.587,2.587,0,0,1,7.5,9.827Z"/></svg>');
  }
  &_logout {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16"><path d="M14.95,6.637a0.628,0.628,0,0,0-.136-0.2L12.939,4.56a0.625,0.625,0,1,0-.884.883l0.809,0.808H9.371a0.625,0.625,0,1,0,0,1.249h3.492l-0.808.808a0.625,0.625,0,0,0,.884.883l1.875-1.873a0.615,0.615,0,0,0,.136-0.2A0.624,0.624,0,0,0,14.95,6.637ZM10.622,8.749A0.625,0.625,0,0,0,10,9.374V12.5H7.5V2.5A0.625,0.625,0,0,0,7.05,1.9L4.879,1.254H10V4.377a0.625,0.625,0,0,0,1.25,0V0.629A0.625,0.625,0,0,0,10.622,0h-10A0.564,0.564,0,0,0,.554.017,0.618,0.618,0,0,0,.289.109C0.275,0.118.258,0.118,0.245,0.128S0.238,0.139.233,0.143a0.619,0.619,0,0,0-.166.2A0.5,0.5,0,0,0,.05.393,0.6,0.6,0,0,0,0,.539,0.39,0.39,0,0,0,0,.593C0,0.606-.007.617-0.007,0.629V13.121a0.624,0.624,0,0,0,.5.612l6.252,1.249A0.578,0.578,0,0,0,6.87,15,0.625,0.625,0,0,0,7.5,14.371V13.746h3.126a0.625,0.625,0,0,0,.625-0.625V9.374A0.625,0.625,0,0,0,10.622,8.749Z"/></svg>');
  }
  &_paper {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15"><path d="M14.3,3.275v-2.1H-0.4v11.55a2.057,2.057,0,0,0,2.1,2.1H14.825s1.575-.033,1.575-2.1V3.275H14.3ZM1.7,13.775a1.028,1.028,0,0,1-1.05-1.05V2.225h12.6v10.5a1.519,1.519,0,0,0,.355,1.05H1.7Zm0-9.45H12.2v1.05H1.7V4.325Zm5.775,6.3H11.15v1.05H7.475v-1.05Zm0-2.1H12.2v1.05H7.475V8.525Zm0-2.1H12.2v1.05H7.475V6.425Zm-5.775,0H6.425v5.25H1.7V6.425Z"/></svg>');
  }
  &_scales {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13"><path d="M9.106,11.9H8.112V2.591H12.3L10.528,5.468a0.316,0.316,0,0,0,.1.431,0.3,0.3,0,0,0,.163.048A0.3,0.3,0,0,0,11.046,5.8l1.54-2.5,1.54,2.5a0.3,0.3,0,0,0,.422.1,0.316,0.316,0,0,0,.1-0.431L12.868,2.591H12.89a0.484,0.484,0,0,0,0-.968H9.4A1.556,1.556,0,0,0,8.575,1a1.078,1.078,0,0,0-2.15,0,1.556,1.556,0,0,0-.828.619H2.109a0.484,0.484,0,0,0,0,.968H2.132L0.356,5.468a0.316,0.316,0,0,0,.1.431,0.3,0.3,0,0,0,.163.048A0.3,0.3,0,0,0,.873,5.8l1.54-2.5,1.54,2.5a0.3,0.3,0,0,0,.422.1,0.316,0.316,0,0,0,.1-0.431L2.7,2.591H6.888V11.9H5.893A1.09,1.09,0,0,0,4.815,13h5.369A1.09,1.09,0,0,0,9.106,11.9ZM4.664,6.279h-4.5A0.165,0.165,0,0,0,0,6.445,2.442,2.442,0,0,0,2.413,8.909,2.442,2.442,0,0,0,4.827,6.445,0.165,0.165,0,0,0,4.664,6.279Zm10.173,0h-4.5a0.165,0.165,0,0,0-.163.166,2.414,2.414,0,1,0,4.827,0A0.165,0.165,0,0,0,14.837,6.279Z"/></svg>');
    
    &.active {
      background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="rgb(184,53,57)" width="16" height="13" viewBox="0 0 16 13"><path d="M9.106,11.9H8.112V2.591H12.3L10.528,5.468a0.316,0.316,0,0,0,.1.431,0.3,0.3,0,0,0,.163.048A0.3,0.3,0,0,0,11.046,5.8l1.54-2.5,1.54,2.5a0.3,0.3,0,0,0,.422.1,0.316,0.316,0,0,0,.1-0.431L12.868,2.591H12.89a0.484,0.484,0,0,0,0-.968H9.4A1.556,1.556,0,0,0,8.575,1a1.078,1.078,0,0,0-2.15,0,1.556,1.556,0,0,0-.828.619H2.109a0.484,0.484,0,0,0,0,.968H2.132L0.356,5.468a0.316,0.316,0,0,0,.1.431,0.3,0.3,0,0,0,.163.048A0.3,0.3,0,0,0,.873,5.8l1.54-2.5,1.54,2.5a0.3,0.3,0,0,0,.422.1,0.316,0.316,0,0,0,.1-0.431L2.7,2.591H6.888V11.9H5.893A1.09,1.09,0,0,0,4.815,13h5.369A1.09,1.09,0,0,0,9.106,11.9ZM4.664,6.279h-4.5A0.165,0.165,0,0,0,0,6.445,2.442,2.442,0,0,0,2.413,8.909,2.442,2.442,0,0,0,4.827,6.445,0.165,0.165,0,0,0,4.664,6.279Zm10.173,0h-4.5a0.165,0.165,0,0,0-.163.166,2.414,2.414,0,1,0,4.827,0A0.165,0.165,0,0,0,14.837,6.279Z"/></svg>');
    }
  }
  &_settings {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><path d="M16.643,7.933a0.534,0.534,0,0,0-.527-0.4,1.811,1.811,0,0,1-1.238-3.152,0.456,0.456,0,0,0,.05-0.618,8.112,8.112,0,0,0-1.3-1.312,0.456,0.456,0,0,0-.623.05,1.887,1.887,0,0,1-2.043.46A1.822,1.822,0,0,1,9.857,1.176,0.455,0.455,0,0,0,9.455.7a8.171,8.171,0,0,0-1.843,0,0.456,0.456,0,0,0-.406.469,1.825,1.825,0,0,1-1.122,1.75,1.892,1.892,0,0,1-2.028-.462A0.457,0.457,0,0,0,3.438,2.4,8.141,8.141,0,0,0,2.112,3.708a0.457,0.457,0,0,0,.049.623,1.819,1.819,0,0,1,.458,2.046A1.894,1.894,0,0,1,.837,7.487a0.446,0.446,0,0,0-.473.4,8.232,8.232,0,0,0,0,1.865,0.542,0.542,0,0,0,.537.4,1.8,1.8,0,0,1,1.686,1.124,1.825,1.825,0,0,1-.458,2.028,0.456,0.456,0,0,0-.05.618,8.134,8.134,0,0,0,1.3,1.313,0.455,0.455,0,0,0,.624-0.049,1.884,1.884,0,0,1,2.043-.46,1.819,1.819,0,0,1,1.108,1.78,0.455,0.455,0,0,0,.4.479,8.144,8.144,0,0,0,1.843,0,0.456,0.456,0,0,0,.406-0.47,1.823,1.823,0,0,1,1.121-1.749,1.889,1.889,0,0,1,2.029.462,0.458,0.458,0,0,0,.618.052,8.167,8.167,0,0,0,1.325-1.312,0.456,0.456,0,0,0-.049-0.623A1.812,1.812,0,0,1,16.059,10.2l0.1,0a0.456,0.456,0,0,0,.48-0.4A8.222,8.222,0,0,0,16.643,7.933ZM8.515,11.6a2.736,2.736,0,1,1,2.733-2.736A2.737,2.737,0,0,1,8.515,11.6Z"/></svg>');
  }
  &_signin {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15"><path d="M12.497,6a3.5,3.5,0,0,1-3.5-3.5c0-.1138.0229-.2213.0335-.3323a7.23,7.23,0,0,0-1.5338-.1649A7.6622,7.6622,0,0,0,0,9.82,8.0141,8.0141,0,0,0,1.1683,14h.7684a4.7816,4.7816,0,0,1,3.0741-2.2955,3.4743,3.4743,0,0,0,4.926.0474A4.7173,4.7173,0,0,1,12.87,14h.9557A8.0573,8.0573,0,0,0,13.87,5.719,3.4861,3.4861,0,0,1,12.497,6ZM8.7819,11.4773a2.5421,2.5421,0,0,1-2.6151-.0255A3.5743,3.5743,0,0,1,4.5375,8.3544,3.25,3.25,0,0,1,7.4968,4.8825a3.2505,3.2505,0,0,1,2.9594,3.4719A3.5615,3.5615,0,0,1,8.7819,11.4773Zm3.9659-9.2281V0h-.7516V2.2492H9.7415v.7516h2.2548V5.2556h.7516V3.0008H14.997V2.2492Z"/></svg>');
  }
  &_wallet {
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><path d="M4.181,3.723l6.465-1.764L10.3,1.268A0.911,0.911,0,0,0,9.084.856L3.3,3.723H4.181ZM12.653,2.01a0.91,0.91,0,0,0-.24.033l-1.519.415L6.256,3.723h7.559L13.531,2.682A0.909,0.909,0,0,0,12.653,2.01Zm2.111,2.35H1.3a0.912,0.912,0,0,0-.88.672,0.942,0.942,0,0,0-.033.238v10.14a0.911,0.911,0,0,0,.91.91H14.762a0.911,0.911,0,0,0,.911-0.91V12.872h-5.4a1.549,1.549,0,0,1-1.548-1.547V9.3a1.545,1.545,0,0,1,1.546-1.545h5.4V5.27A0.908,0.908,0,0,0,14.763,4.36Zm1.548,4.265a0.908,0.908,0,0,0-.319-0.189A0.929,0.929,0,0,0,15.7,8.386H10.269a0.911,0.911,0,0,0-.91.91v2.026a0.911,0.911,0,0,0,.91.91H15.7a0.927,0.927,0,0,0,.295-0.049,0.88,0.88,0,0,0,.319-0.189,0.906,0.906,0,0,0,.3-0.672V9.3A0.906,0.906,0,0,0,16.311,8.625ZM12.17,10.491a0.455,0.455,0,0,1-.455.455h-0.3a0.455,0.455,0,0,1-.455-0.455v-0.3a0.446,0.446,0,0,1,.175-0.357,0.46,0.46,0,0,1,.28-0.1h0.3a0.455,0.455,0,0,1,.455.455v0.3h0Z"/></svg>');
  }
}