.find-workers {
	margin-top: -7px;
	width: 100%;
	min-height: 500px;
	display: block;

	@include bp($lg) {
		display: flex;
		justify-content: space-between;
	}

	&__content {
		min-height: 500px;

		@include bp($lg) {
			width: 775px;
		}
	}

	&__item {
		transition:  all .2s ease;
		display: grid;
		grid-template-columns: auto 1fr;
		padding: 20px 0;
	
		&:hover {
			background-color: rgb(243, 251, 255);
	
			.find-item__phone {
				opacity: 1;

				&-company {
					opacity: 1;
				}
			}
		}


	}
}

.left {
	box-sizing: border-box;
	margin: 0 15px 15px;
}

.right {
	// for button .find-item__phone
	position: relative;

	@include bp($md) {
		padding-right: 15px;
	}
}

.page-content-title {
	padding: 0 15px;
	font-family: Geometria;
	font-size: 16px;
	margin: 0;
	margin-bottom: 20px;

	@include bp($sm) {
		font-size: 1.4rem;
	}

	span {
	 font-family: "Geometria-Light";
	 color: #757575;
	 font-size: 14px;
	 margin-left: 0.5rem;
	}
}

.banner-resume {
	box-sizing: border-box;
	margin: 0 15px 20px;
	padding: 50px 0;
	padding-left: 20px;

	background-color: #f2fbf0;
	background-image: url('../img/banner-resume.png');
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 30%;

	@include bp($sm) {
		padding: 20px 0;
		padding-left: 20px;
		background-size: 20%;
	}

	@include bp($xsm) {
		padding: 50px;
	}

	@include bp($md) {
		padding-left: 123px;
	}

	// .banner-resume
	&__title {
		margin: 0;
		margin-bottom: 13px;
		color: #2e3e55;
		font-family: "Open Sans";
		font-size: 16px;
		font-weight: 400;
		line-height: 1.2;
	}

	// .banner-resume
	&__btn {
		outline: none;
		border: none;
		width: 227px;
		height: 45px;
		border-radius: 3px;
		background-color: #8bc72a;
		color: $c-main;
		font-family: "Open Sans";
		font-size: 14px;
		font-weight: 400;
		cursor: pointer;
		transition: all .2s ease;
		
		&:hover {
			color: $c-main;
			transition: all .2s ease;
			border-color: #619f00;
			background: #619f00;
		}
	}
}

.avatar-wrapp{
	width: 61px;
	height: 61px;
	border: 1px solid #e3e6e9;
	background-color: $c-main;
	box-sizing: border-box;
	padding: 1px;
	border-radius: 50%;
	// margin-left: 15px;
	img{
		width: 100%;
	}
}

.find-item__title {
	display: inline-block;
	margin-right: 15px;
	margin-bottom: .5em;
	opacity: 0.85;
	color: #142542;
	font-family: "Open Sans";
	font-weight: 400;
	position: relative;
	text-transform: uppercase;

	&:hover{
		cursor: pointer;
		text-decoration: underline;
		color: #619f00;
	}

	@include bp($md) {
		font-size: 1.2rem;
	}

	&.job-seeker {
		text-transform: initial;
	}

	.urgently {
		display: inline-block;
		margin-left: 1rem;
		width: 70px;

		background-color: #fbd91a;
		border-radius: 2px;
		color: #000;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.6;
		text-align: center;
		text-transform: uppercase;

		@include bp($xsm) {
			margin-left: 2rem;
		}
	}
}

.find-workers__item--mod {
	.find-item__info--price {
		border-bottom: 1px solid #fef7da !important;
	}
	.find-item__info--city,
	.find-item__info--ray,
	.find-item__info--data{
		border-bottom: 1px solid #fef7da !important;
	}	
}


.find-item__info {
	margin: 0;
	margin-bottom: 10px;
	font-size: 13px;

	&--ray {

		&_mob {

			@include bp($xsm) {
				display: none;
			}
		}

		&__mob {
			@include bp($sm) {
				display: none;
			}
		}

		&__pc {
			display: none;

			@include bp($sm) {
				display: inline-block;
			}
		}
	}

	&-mob {
		box-sizing: border-box;
		margin-top: 1rem;

		// HACK. Align to corner
		margin-left: -100px;

		display: flex;
		justify-content: space-between;
		padding: .2em 15px .2em 35px;
		border-top: 1px solid rgba(#b3b3b3, .4);
		border-bottom: 1px solid rgba(#b3b3b3, .4);

		@include bp($xsm) {
			display: none;
		}
	}

	&-pc {
		display: none;

		@include bp($xsm) {
			display: inline-block;
		}
	}

	&-wrapper {
		box-sizing: border-box;
		margin-top: 1rem;

		// HACK. Align to corner
		margin-left: -100px;

		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: .2em 15px .2em 35px;
		border-top: 1px solid rgba(#b3b3b3, .4);
		border-bottom: 1px solid rgba(#b3b3b3, .4);
		font-size: .9em;

		@include bp($sm) {
			justify-content: initial;
		}

		@include bp($xsm) {
			margin-top: 0;
			padding: 0;
			margin-left: 0;

			border: none;
			font-size: 1em;
		}

		& .point {

			&:first-of-type {
				display: none;

				@include bp($xsm) {
					display: inline-block;
				}
			}

			&:last-of-type {
				@include bp($sm) {
					display: none;
				}
			}
		}
	}

	.point {
		display: inline-block;
		margin-right: .2rem;

		@include bp($sm) {
			margin-right: .5rem;
		}
	}

	// .find-item__info
	&--price {
		display: block;
		margin-right: .5rem;
		margin-bottom: .2em;

		color: #619f00;
		font-family: "Open Sans";
		font-size: 14px;
		font-weight: 300;
		text-decoration: none;

		&:hover{
			text-decoration: underline;
		}

		@include bp($lg) {
			display: inline;
			margin-right: .4em;
			margin-bottom: 0;
		}

		// .find-item__info--price
		&_inline {
			display: inline-block;
		}
	}
	
	// .find-item__info
	&--city,
	&--ray,
	&--data{
		color: #435269;
		font-family: "Open Sans";
		font-weight: 300;	
		text-decoration: none;
		cursor: pointer;
		border-bottom: 1px solid $c-main;
		margin-right: .2rem;

		@include bp($sm) {
			margin-right: .5rem;
		}
	}

	&--data {
		@include bp($sm) {
			margin-left: auto;
		}

		@include bp($xsm) {
			margin-left: initial;
		}
	}

	// .find-item__info
	&--city:hover{
		color: #619f00;
	}
}

.find-item__phone {
	display: none;

	@include bp($xsm) {
		display: block;
		position: absolute;
		top: 0;
		right: 25px;
	
		font-size: .8rem;
		opacity: 0;
		transition: .2s;
	}

	&-company {
		display: none;
		
		@include bp($xsm) {
			display: block;
			position: absolute;
			top: 0;
			right: 25px;
		
			font-size: .8rem;
			opacity: 0;
			transition: .2s;

			padding: 2px 8px 4px;
			background-color: $c-main;
			border: 1px solid $c-secondary;
			border-radius: 2px;
			cursor: pointer;
			transition: .2s;

			&_active {
				border: none;
				background-color: transparent;
				cursor: default;
				font-size: 1rem;
			}
		}
	}

	.button {
		padding: 2px 8px 4px;
		background-color: $c-main;
		border: 1px solid $c-secondary;
		border-radius: 2px;
		cursor: pointer;
		transition: .2s;

		&_active {
			border: 1px solid $c-accent1;
			background-color: $c-accent1;
			color: $c-main;
		}
	}

	&-popup {
		padding-top: 20px;
		position: absolute;
		right: 0;
		
		color: $c-secondary;
		line-height: 1.4;

		@include bp($lg) {
			right: 50%;
			transform: translateX(50%);
		}

		&-link {
			display: block;
			text-decoration: none;
			white-space: initial;

			span {
				display: inline-block;
				background-image: linear-gradient(to right, $c-accent1 40%, rgba($c-accent1, 0) 20%);
				background-position: bottom;
				background-size: 3px 1px;
				background-repeat: repeat-x;
				color: $c-accent1;
				white-space: nowrap;
			}
		}

		&-wrapper {
			// min-width: 100px;
			padding: 8px;
			position: relative;
			background-color: $c-main;
			box-shadow: 0 0 2.2rem -.8rem $c-secondary;
		}

		.arrow {
			border: 16px solid transparent;
			border-bottom: 16px solid $c-main;
			position: absolute;
			top: -24px;
			left: 70%;

			@include bp($lg) {
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.text {
			white-space: nowrap;
		}
	}
}

.recomend-grey-bg{
	.find-item__info--price{
		border-bottom: 1px solid #f2f2f2;
	}
	.find-item__info--city,
	.find-item__info--ray,
	.find-item__info--data{
		border-bottom: 1px solid #f2f2f2;
	}
}

.recomend-green-bg{
	.find-item__info--price{
		border-bottom: 1px solid #f4fbf0;
	}
	.find-item__info--city,
	.find-item__info--ray,
	.find-item__info--data{
		border-bottom: 1px solid #f4fbf0;
	}
}

.find-item__info--last{
	// HACK to align to corner
	margin-left: -80px;

	padding: 0 15px;

	@include bp($md) {
		margin: 0;
		padding: 0;
	}

	.point {
		display: inline-block;
		margin: .2em;
	}

	.fint-item--sex{
		color: #435269;
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 300;
		text-decoration: none;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.find-item--age,
	.find-item--sj,
	.find-item--inde {
		color: #435269;
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 300;	
		text-decoration: none;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}	
}

.find-item__text {
	// HACK. Align to corner
	margin-left: -80px;

	max-width: 567px;
	padding: 0 15px;

	opacity: 0.9;
	color: #435269;
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 400;

	@include bp($md) {
		margin-left: 0;
		padding: 0;
	}
}

.find-workers__item--mod{
	transition: all 0.2s ease;
	background-color: #fef7da;
	margin-bottom: 24px;

	&:hover {
		background-color: #fef7da;
	}

	.find-item__info--price{
		width: 92px;
		text-align: center;
		padding: .2em 0;
		background-color: #619f00;
		color: #f6f7fb;
		font-family: "Open Sans";
		font-size: 14px;
		font-weight: 400;
		cursor: pointer;
	}
	.find-item__info {
		margin-top: 6px;
		margin-bottom: 6px;
	}
	.find-item__title {
		display: inline-block;
		font-weight: 400;
		color: #f2412f;
		position: relative;
		transition: .2s;
		line-height: 1.2;
	}
}

.heart--activ{
	display: inline-block;
	width: 19px;
	height: 16px;
	background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="16px"><path fill-rule="evenodd" stroke="rgb(106, 165, 15)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="rgb(106, 165, 15)" d="M18.500,5.487 C18.500,2.732 16.324,0.499 13.639,0.499 C11.886,0.499 10.355,1.454 9.500,2.882 C8.645,1.454 7.113,0.499 5.360,0.499 C2.676,0.499 0.500,2.732 0.500,5.487 C0.500,6.987 1.148,8.329 2.168,9.242 L9.056,16.311 C9.174,16.432 9.333,16.499 9.500,16.499 C9.666,16.499 9.826,16.432 9.943,16.311 L16.831,9.242 C17.852,8.329 18.500,6.987 18.500,5.487 Z"/></svg>');
	margin-right: 10px;
}


.page-email-wrapper{
	min-height: 136px;
	background-color: #f5f7f7;
	box-sizing: border-box;
	padding-top: 18px;
	padding: 20px;
	margin-top: 20px;
}

.email-title{
	margin: 0;
	margin-bottom: 15px;
	color: #2e3e55;
	font-family: "Open Sans";
	font-size: 15px;
	font-weight: 400;
}

.email-form{
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;

	@include bp($xsm) {
		flex-direction: row;
	}

	input{
		border: none;
		outline: none;
		height: 45px;
		border-radius: 3px;
		background-color: $c-main;
		color: #2e3e55;
		font-family: "Open Sans";
		font-size: 15px;
		font-weight: 400;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 34px;
		box-sizing: border-box;
		background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13px" height="8px"><path fill-rule="evenodd" fill="rgb(124, 135, 151)" d="M11.994,-0.002 L1.005,-0.002 C0.455,-0.002 -0.000,0.391 -0.000,0.867 L-0.000,7.131 C-0.000,7.606 0.455,7.999 1.005,7.999 L11.994,7.999 C12.544,7.999 12.999,7.606 12.999,7.131 L12.999,0.867 C13.023,0.391 12.568,-0.002 11.994,-0.002 ZM8.235,4.236 L12.425,1.445 L12.425,7.048 L8.235,4.236 ZM1.029,0.515 L11.994,0.515 C12.185,0.515 12.353,0.639 12.400,0.784 L6.847,4.505 C6.631,4.650 6.272,4.650 6.080,4.505 L0.598,0.826 C0.646,0.660 0.814,0.515 1.029,0.515 ZM0.598,7.048 L0.598,1.487 L4.740,4.257 L0.598,7.048 ZM1.029,7.523 C0.981,7.523 0.934,7.523 0.910,7.503 L5.243,4.588 L5.721,4.918 C5.937,5.063 6.200,5.125 6.464,5.125 C6.727,5.125 7.014,5.063 7.206,4.918 L7.732,4.567 L12.113,7.503 C12.065,7.523 12.041,7.523 11.994,7.523 L1.029,7.523 L1.029,7.523 Z"/></svg>');
		background-repeat: no-repeat;
		background-size: 13px 8px;
		background-position: 14px center;

		margin-bottom: 1rem;

		@include bp($xsm) {
			width: 318px;
		}
	}
}

.email-btn{
	height: 45px;
	border-radius: 3px;
	background-color: #8bc72a;
	border: none;
	outline: none;
	color: $c-main;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	transition: all 200ms ease;

	&:hover{
		border-color: #619f00;
		background: #619f00;
	}

	@include bp($xsm) {
		width: 164px;
	}
}

.title-img{
	margin-bottom: 32px;
}
