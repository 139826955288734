.hr_select {
  margin: .7em 0;
}

input[name="select__sector"],
input[name="select__subway-moskow"],
input[name="select__region"],
input[name="select__district"],
input[name="select__locality"]{
  display: none;

  &:disabled + .select__popup-label {
    opacity: .4;
  }
  
  &:checked + .select__popup-label {
    background-color: $c-accent2;
    
    &::before {
      border-color: #f00;
    }

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-image: url(../img/icons/checked.png);
      background-size: 80%;
      background-position: center 40%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &_inner {
      &::after {
        left: .7rem;
      }
    }

    &_sub {
      &::after {
        left: 2.5rem;
      }
    }
  }
}

.select__popup {
  position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
  background-color: $c-main;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
	border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  z-index: 99;
  transition: .5s ease;

  &-btn-clear {
    justify-self: end;
    color: $c-accent1;
    cursor: pointer;
    font-size: .7em;
    text-transform: uppercase;

    @include bp($sm) {
      font-size: .8em;
    }

    &-input {
      display: none;
    }
  }

  &-btn-close {
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 16px;
    height: 16px;
    background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px"><path fill="rgb(10,10,10)" d="M17.026,1.959 L9.964,9.022 L17.026,16.085 C17.284,16.342 17.284,16.761 17.026,17.020 C16.897,17.149 16.727,17.213 16.558,17.213 C16.389,17.213 16.219,17.149 16.090,17.020 L9.028,9.958 L1.966,17.020 C1.837,17.149 1.668,17.213 1.499,17.213 C1.329,17.213 1.160,17.149 1.031,17.020 C0.772,16.761 0.772,16.342 1.031,16.085 L8.093,9.022 L1.031,1.959 C0.772,1.702 0.772,1.282 1.031,1.025 C1.289,0.765 1.708,0.765 1.966,1.025 L9.028,8.087 L16.090,1.025 C16.348,0.765 16.767,0.765 17.026,1.025 C17.284,1.282 17.284,1.702 17.026,1.959 Z"/></svg>');
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &-title {
    font-weight: bold;
    align-self: start;
    line-height: .9;
    font-size: .95em;

    @include bp($sm) {
      font-size: 1em;
    }

    &_margin {
      margin-bottom: 1rem;
    }
  }

  &-label {
    display: block;
    padding: .2rem 0;
    position: relative;
    padding-left: 1.5rem;
    
    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      border: 1px solid rgba(0,0,0,.4);
      border-radius: 5px;
    }

    &_inner {
      padding-left: 2.2rem;

      &::before {
        left: .7rem;
      }
    }

    &_sub {
      padding-left: 4rem;

      &::before {
        left: 2.5rem;
      }
    }

    &.poiner {
      border: none;
      width: 16px;
      height: 16px;
      background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#ffde00" stroke="rgb(0,0,0)" width="22" height="22" viewBox="0 0 22 22"><circle cx="11" cy="11" r="9.719"/><path d="M5.765,10.967a0.476,0.476,0,0,1,0-.688l0.687-.687a0.475,0.475,0,0,1,.687,0L7.188,9.641l2.7,2.9a0.237,0.237,0,0,0,.344,0l6.576-6.823h0.049a0.474,0.474,0,0,1,.687,0L18.231,6.4a0.475,0.475,0,0,1,0,.687h0l-7.852,8.149a0.473,0.473,0,0,1-.687,0L5.863,11.113Z"/></svg>');
      background-repeat: no-repeat;
      background-size: contain;
      padding: 0;
      position: absolute;

      &::before {
        display: none;
      }

      &[data-value="Авиамоторная"] {
        top: 252px;
        right: 94px;
      }
      &[data-value="Автозаводская"] {
        bottom: 262px;
        right: 184px;
      }
      &[data-value="Академическая"] {
        bottom: 203px;
        left: 207px;
      }
      &[data-value="Александровский сад"] {
        top: 323px;
        left: 235px;
      }
      &[data-value="Алексеевская"] {
        top: 98px;
        right: 183px;
      }
      &[data-value="Алма-Атинская"] {
        bottom: 82px;
        right: 184px;
      }
      &[data-value="Алтуфьево"] {
        top: 21px;
        right: 249px;
      }
      &[data-value="Аннино"] {
        bottom: 87px;
        right: 269px;
      }
      &[data-value="Арбатская"] {
        top: 308px;
        left: 220px;
      }
      &[data-value="Аэропорт"] {
        top: 76px;
        left: 208px;
      }
      &[data-value="Бабушкинская"] {
        top: 36px;
        right: 183px;
      }
      &[data-value="Багратионовская"] {
        top: 226px;
        left: 86px;
      }
      &[data-value="Баррикадная"] {
        top: 194px;
        left: 195px;
      }
      &[data-value="Бауманская"] {
        top: 185px;
        right: 96px;
      }
      &[data-value="Беговая"] {
        top: 165px;
        left: 193px;
      }
      &[data-value="Белорусская"] {
        top: 174px;
        left: 237px;
      }
      &[data-value="Библиотека им. Ленина"] {
        top: 319px;
        left: 247px;
      }
      &[data-value="Битцевский парк"] {
        bottom: 67px;
        left: 206px;
      }
      &[data-value="Борисово"] {
        bottom: 121px;
        right: 167px;
      }
      &[data-value="Боровицкая"] {
        top: 331px;
        left: 251px;
      }
      &[data-value="Боровское шоссе"] {
        bottom: 35px;
        left: 116px;
      }
      &[data-value="Ботанический сад"] {
        top: 66px;
        right: 183px;
      }
      &[data-value="Братиславская"] {
        bottom: 146px;
        right: 167px;
      }
      &[data-value="Бульвар Адмирала Ушакова"] {
        bottom: 31px;
        right: 269px;
      }
      &[data-value="Бульвар Дмитрия Донского"] {
        bottom: 72px;
        right: 269px;
      }
      &[data-value="Бульвар Рокоссовского"] {
        top: 21px;
        right: 97px;
      }
      &[data-value="Бунинская Аллея"] {
        bottom: 5px;
        right: 269px;
      }
      &[data-value="Бутырская"] {
        bottom: 5px;
        right: 269px;
      }
    }
  }

  &-submit {
    width: 100%;
    background-color: $c-accent1;
    border: 0;
    border-radius: 5px;
    color: $c-main;
    font-size: 1em;
    line-height: 2;
    outline: 0;
    transition: .2s;
    grid-column: 1 / -1;
    justify-self: center;

    &:hover {
      background-color: #b83539;
    }

    @include bp($xs) {
      width: 300px;
    }

    @include bp($sm) {
      font-size: 1rem;
      line-height: 3;
    }
  }
}

.select__sector {
  box-sizing: border-box;
  padding: 3rem 1rem 1rem;
  width: 90%;

  @include bp($xsm) {
    padding: 3rem 2rem 2rem;
    width: 605px;
  }

  &-form {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
    font-size: .9rem;
  }

  &-wrapper {
    box-sizing: border-box;
    grid-column: 1 / -1;
    max-height: 65vh;
    margin-bottom: 1rem;
    padding: .5rem 0;
    overflow-y: scroll;
  }
}


.select__region {
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: scroll;
  width: 90%;  
  max-width: 946px;
  padding: 3rem 1rem 1rem;

  @include bp($xsm) {
    padding: 3rem 2rem 2rem;
  }

  @include bp($xl) {
    overflow-y: initial;
  }
  
  &-form {
    display: grid;
    grid-gap: 2rem;
    font-size: .9rem;

    @include bp($md) {      
      grid-template-columns: 1fr 1fr;
    }
  }

  &-col {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-gap: 1rem;
  }

  &-wrapper {
    box-sizing: border-box;
    grid-column: 1 / -1;
    max-height: 65vh;
    padding: .5rem 0;
    overflow-y: scroll;
    border: 1px solid rgba(0,0,0,.2);
  }
}

.select__district {
  // width: 70%;
  align-items: center;
  justify-items: center;
  display: grid;
  width: 950px;
  height: 450px;

  &-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem 2rem;
    width: 90%;
  }

  &-col {
    padding: .5rem 0;
    border: 1px solid rgba(0,0,0,.2);
    min-height: 200px;
  }

  button {
    margin-top: 1rem;
  }
}

.select__subway {
  box-sizing: border-box;
  max-height: 95vh;
  width: 960px;
  padding: 2rem;
  overflow: auto;

  &-form {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-rows: auto 660px auto;
    grid-gap: 1rem;
    height: 757px;
  }
  
  &-map {
    display: block;
    width: 100%;
  }

  &-col {
    // for pointers
    position: relative;

    &:last-of-type {
      height: 100%;
      overflow-y: scroll;
    }
  }
}