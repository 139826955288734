.recovery {

	@include bp($lg) {
		align-items: center;
		display: grid;
		grid-column-start: 2;
		background-image: url(../img/bg_recovery.svg);
		background-position: right center;
		background-repeat: no-repeat;
		background-size: 40%;
	}

	&__wrapper {
		padding: 0 15px;
	}

	&__title {
		font-size: 1.5rem;

		@include bp($sm) {
			font-size: 2rem;
		}

		@include bp($xsm) {
			font-size: 2.5rem;
		}
	}

	&__text {
		margin-bottom: 1rem;
		font-size: .9rem;
		grid-column: 1 / -1;

		@include bp($xsm) {
			margin-bottom: 2rem;
		}
	}

	&__form {
		display: grid;
		grid-gap: 1rem;
		max-width: 620px;

		@include bp($xsm) {
			grid-template-columns: 3fr 2fr;
		}

		input {
			box-sizing: border-box;
			outline: none;
			padding: 0 .5rem;
			height: 45px;
			width: 100%;

			border: none;
			border-radius: 5px;
			background-color: $c-main;
			color: #2e3e55;
			font-family: $ff-main;
			font-size: 15px;
			font-weight: 400;
		}

    // .recovery__form
		button {
			width: 100%;
			height: 45px;
			border-radius: 5px;
			background-color: $c-accent1;
			border: none;
			outline: none;
			color: $c-main;
			font-family: $ff-main;
			font-size: 14px;
			transition: all 200ms ease;

			&:hover {
				background-color:	#b83539;
			}
		}
  }
  
  // .recovery
  &__login-btn {
    color: $c-accent1;

    &:hover {
      border-bottom: 1px dashed;
    }
	}
	
	&__error {
		color: $c-accent1;
		grid-column: 1 / -1;

		&-link {
			color: inherit;
			text-decoration: none;
			background-image: linear-gradient(to right, $c-accent1 40%, rgba($c-accent1, 0) 20%);
			background-position: bottom;
			background-size: 3px 1px;
			background-repeat: repeat-x;
		}
	}

	&__succes	{
		max-width: 620px;
	}

	&__hr {
		margin: 2rem 0;
	}
}