@import "base/variables";
@import "base/mixins";
@import "base/typography";
@import "vendor/reset";

body,html {
	padding: 0;
	margin: 0;

	color: $c-secondary;
	font-family: $ff-main;
	line-height: 1.2;
	overflow-x: hidden;
}

strong {
	font-weight: bold;
}

.hr {
	height: 1px;
	background-color: #b3b3b3;
	opacity: 0.4;

	&_nav {
		margin: .5rem 0;
	}
}

.recomend-item{
	width: 155px;
	height: 30px;
	background-color: #619f00;
	color: $c-main;
	font-family: $ff-main;
	font-size: 14px;
	font-weight: $fw-r;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	margin-bottom: 3px;

	display: none;

	@include bp($md) {
		display: flex;
	}

	&_mob {
		display: flex;
		grid-column: 1 / -1;
		margin-bottom: 1rem;
		margin-left: 15px;

		@include bp($md) {
			display: none;
		}
	}
}

.mb15{
	margin-bottom: 15px;
}
.mt15{
	margin-top: 15px;
}

.wrapper {
	box-sizing: border-box;
	max-width: 1170px;
	// padding-left: 15px;
	// padding-right: 15px;
	margin: 0 auto;
}

.page-content{
	padding: 40px 0 0;
}

.d-none{
	display: none !important;
}


.d-none-over{
	top: -10% !important;
	transform: translate(-50%, -100%) !important;
	transition: 1.5s ease;
}

.point{
	display: block;
	width: 2px;
	height: 2px;
	background-color: #565656;
}

// cat banner
.cat-banner {
	margin-bottom: 20px;
	min-height: 218px;
	background-color: #ffdfe1;
	background-image: url('../img/main-cat.svg');
	background-position: center top 20px;
	background-size: 215px;
	background-repeat: no-repeat;
	box-sizing: border-box;
	padding: 150px 15px 50px;
	
	display: flex;
	justify-content: center;

	@include bp($xsm) {
		justify-content: flex-end;
		background-position: left 20px bottom 20px;
		padding-top: 50px;
	}

	@include bp($md) {
		background-size: 300px;
	}

	&:hover {
		background-image: url('../img/main-cat-hover.svg');
	}

	@include bp($lg) {
		display: flex;
	}

	.cat-wrapper-text{
		text-align: center;

		@include bp($xsm) {
			text-align: right;
		}
	}

	p {
		margin-top: 0;
		max-width: 426px;
		color: #2e3e55;
		font-family: $ff-main;
		font-size: 16px;
		font-weight: $fw-r;
	}
	.bbh-btn {
		display: inline-block;
		width: 100%;
		border-radius: 3px;
		background-color: $c-accent1;
		color: $c-main;

		font-family: $ff-main;
		font-size: 14px;
		font-weight: $fw-r;
		line-height: 3;
		text-align: center;
		text-decoration: none;
		transition: all .2s ease;
		
		&:hover{
			background-color: #b83539;
		}

		@include bp($xsm) {
			width: 141px;
		}
	}
}

.popup-soc{
	grid-column-start: 1;
	box-sizing: border-box;
	margin-top: 10px;
	padding: 15px;
	position: relative;
	width: 100%;

	background: #eff9fd;
	text-align: left;
	color: #2e3e55;
	font-family: $ff-main;
	font-size: 15px;
	font-weight: $fw-r;

	&::before{
    z-index: 1;
    top: -25px;
    left: 50px;
    border-width: 15px;
    border-color: transparent transparent #eff9fd;
    border-style: solid;
    cursor: default;
    pointer-events: none;
    content: "";
    position: absolute;
	}
	a {
    color: #619f00;
    font-family: $ff-main;
    font-size: 14px;
    font-weight: $fw-r;
    cursor: pointer;
    transition: all .2s ease;	
    text-decoration: none;
	}
	a:hover {
		transition: all .2s ease;
    color: #377800;
	}
	i {
		cursor: pointer;
		font-size: 30px;
		color: #51b5e7;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.exit {
	width: 30px;
	height: 30px;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	span:nth-child(1){
		background-color: #51b5e7;
		display: block;
		width: 20px;
		height: 2px;
		position: absolute;
		left: 50%;
		top: 50%;
		// transform: rotate(45deg);
		transform: translate(-50% -50%);
	}
	span:nth-child(2){
		background-color: #51b5e7;
		display: block;
		width: 20px;
		height: 2px;
		position: absolute;
		left: 50%;
		top: 50%;
		// transform: rotate(-45deg);
		transform: translate(-50% -50%);
	}
}

.popup-soc-wrapp{
	display: flex;
	align-items: center;
	margin-right: 1rem;
	grid-column: 1 / -1;

	@include bp($sm) {
		grid-column: span 1;
	}
}

.active-btn{
  border-radius: 2px;
  border: 1px solid #619f00;
  background-image: url('../img/arrow-left-activ.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 9px 18px;
  transition: all .2s ease;
  cursor: pointer;	
}

.active-btn:hover{
	color: $c-main;
	border-color: #619f00;
	background-color: #619f00;
	background-image: url('../img/arrow.png');
}

.find-item__info--price{
	color: #142542;	
}

.find-item__info--price:hover{
	color: #377800 !important;	
	transition: all 200ms ease;
	text-decoration: none !important;
}
.find-item__info--ray{
	border-bottom: 1px solid $c-main;
	transition: all 200ms ease;
}

.find-item__info--ray:hover{
	text-decoration: none !important;
	border-bottom: dotted #b83539 1px !important;
	transition: all 200ms ease;
	color: #b83539 !important;
}

.header__wrapper > img{
	margin-top: -4px;
}

.banner-resume__btn{
	// display: none;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.overlay {
	position: relative;

	&::before {
		content: '';
		transition: .2s;
	}

	&_active {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba($c-secondary, .7);
			z-index: 5;
		}
	}
}


@import 'parts/nav-mobile';

@import 'parts/aside';
@import 'parts/buttons';
@import 'parts/cookie';
@import 'parts/checkboxes';
@import 'parts/icons';
@import 'parts/header';
@import 'parts/page-navigation';

@import 'parts/filters';
@import 'parts/find-workers';
@import 'parts/find-job';
@import 'parts/popup-form';
@import 'parts/popup-share';
@import 'parts/footer';


@import 'parts/recovery';