.nav-mobile {
  padding-top: 50px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 20%;

  // to overflow .cookie
  z-index: 12;

  background-color: $c-main;
  box-shadow: 0 0 .5rem rgba($c-secondary, .5);
  transition: .4s;
  transform: translateX(100%);
  // display: none;

  @include bp($md) {
    display: none;
  }

  &_registered {
    display: none;
    // display: block;
  }

  &_active {
    transform: translateX(0);
  }

  &__list {
    padding: 0 1em;

    &-item {
      width: 100%;

      &:not(:last-child) {
        // overwrite .header__list-item
        margin-right: 0;

        margin-bottom: .2em;
      }
    }

    &-link {
      width: 100%;
      line-height: 1.2;

      &:hover {
        .border-dotted {
          background-image: linear-gradient(
            to right, $c-accent1 40%, rgba($c-accent1, 0) 20%);
        }
      }

      .icon {
        margin-right: .5rem;

        &:hover {
          background-color: transparent;
        }

        // to align icon with text
        &_paper,
        &_settings,
        &_wallet {
          background-position: center 44%;
        }
      }
    }
  }
}

.border-dotted {
  display: inline-block;
  background-image: linear-gradient(to right, $c-secondary 40%, rgba($c-secondary, 0) 20%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}
.money-counter {
  margin-left: auto;
}