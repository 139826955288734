.find-job{

	.find-item__text{
		margin-bottom: 10px;
	}
	.last-item{
		margin-right: 0;
	}
	.star-no-activ{
		display: inline-block;
		width: 18px;
		height: 18px;
		background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px"><path fill-rule="evenodd" stroke="rgb(106, 165, 15)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M11.901,7.504 L9.487,0.500 L7.005,7.448 L0.501,7.504 L5.848,11.943 L3.926,18.497 L9.487,14.518 L15.048,18.497 L13.126,11.986 L18.498,7.504 L11.901,7.504 Z"/></svg>');
		margin-right: 10px;
	}
	.star-activ{
		display: inline-block;
		width: 18px;
		height: 18px;
		background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px"><path fill-rule="evenodd" stroke="rgb(106, 165, 15)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="rgb(106, 165, 15)" d="M11.901,7.504 L9.487,0.500 L7.005,7.448 L0.501,7.504 L5.848,11.943 L3.926,18.497 L9.487,14.518 L15.048,18.497 L13.126,11.985 L18.498,7.504 L11.901,7.504 Z"/></svg>');
		margin-right: 10px;
	}
	.avatar-wrapp{
		width: 55px;
		height: 55px;
		border: 1px solid rgba($c-secondary, .2);
		border-radius: 2px;
		padding: 2px;

		&_rounded {
			border-radius: 50%;
		}
	}
	.find-item__text a{
		color: #75b8e4;
		text-decoration: none;
	}
	.find-item__text a:hover{
		text-decoration: underline;
	}
}

.recomend-grey-bg,
.recomend-green-bg{
	box-sizing: border-box;
	border-left: 3px solid #dcdcdc;
	background-color: #f2f2f2;
	margin-bottom: 15px;

	&:hover {
		background-color: #f2f2f2;
	}
}

.recomend-green-bg{
	background-color: #f4fbf0;
	border-left: 3px solid #6aa50f;

	&:hover {
		background-color: #f4fbf0;
	}
}

.find-item-fast-icon{
	box-sizing: border-box;
	margin-left: .5rem;
	padding-left: 22px;
	width: 80px;
	
	background-image: url('../img/fast-bg.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 2px;
	
	color: $c-main;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 400;
	line-height: 1.5;
	text-decoration: none;
	text-transform: uppercase;
	
	display: none;

	@include bp($xsm) {
		display: inline-block;
	}

	&_mob {
		grid-column: 1 / -1;
		margin-bottom: 1rem;
		margin-left: 15px;

		@include bp($xsm) {
			display: none;
		}
	}
}


.find-item__soc {
	margin-left: -80px;

	margin-top: 8px;
	padding: 0 15px;

	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: .5rem 0;

	@include bp($sm) {
		grid-template-columns: 200px auto 40px;
		max-width: 500px;

		&_col-2 {
			grid-template-columns: 290px auto;
		}
	}

	@include bp($md) {
		margin-left: 0;
		padding: 0;
		align-items: center;
	}

	.last-item{
		display: flex;
		align-items: center;
	}

	span {
		color: #619f00;
		font-size: 14px;
		font-weight: 400;
		cursor: pointer;
		transition: all 200ms ease;

		&:hover {
			transition: all 200ms ease;
			color: #377800;	
		}
	}

	.more {
		display: inline-block;
		margin-left: 1rem;
		justify-self: end;

		&-content {
			box-sizing: border-box;
			display: none;
			grid-column: 1 / -1;
			margin-top: 1rem;
			padding: 1em 1rem 0;
			width: 100%;
			
			background-color: $c-accent3;
			font-size: .8em;

			p {
				margin-top: 0;
				padding-left: .4rem;
				position: relative;

				&::before {
					content:'';
					display: block;
					position: absolute;
					top: .4em;
					left: -.4em;
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: rgba($c-secondary,.2);

				}
			}
		}
	}

	.heart-no-activ{
		display: inline-block;
		width: 19px;
		height: 16px;
		background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="16px"><path fill-rule="evenodd" stroke="rgb(106, 165, 15)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M18.500,5.487 C18.500,2.732 16.324,0.499 13.639,0.499 C11.886,0.499 10.355,1.455 9.500,2.882 C8.645,1.455 7.113,0.499 5.360,0.499 C2.676,0.499 0.500,2.732 0.500,5.487 C0.500,6.987 1.148,8.329 2.168,9.243 L9.056,16.310 C9.174,16.431 9.333,16.499 9.500,16.499 C9.666,16.499 9.826,16.431 9.943,16.310 L16.831,9.243 C17.852,8.329 18.500,6.987 18.500,5.487 Z"/></svg>');
		margin-right: 10px;
	}

	.form{
		display: inline-block;
		width: 16px;
		height: 17px;
		margin-right: 10px;
		background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="17px"><path fill-rule="evenodd" fill="rgb(106, 165, 15)" d="M12.739,10.584 C11.731,10.584 10.829,11.036 10.230,11.745 L6.335,9.570 C6.456,9.235 6.523,8.875 6.523,8.499 C6.523,8.124 6.456,7.763 6.335,7.428 L10.230,5.253 C10.828,5.962 11.731,6.414 12.739,6.414 C14.537,6.414 16.000,4.975 16.000,3.206 C16.000,1.438 14.537,-0.001 12.739,-0.001 C10.941,-0.001 9.478,1.438 9.478,3.206 C9.478,3.581 9.544,3.942 9.665,4.277 L5.771,6.452 C5.172,5.743 4.270,5.292 3.262,5.292 C1.463,5.292 -0.000,6.731 -0.000,8.499 C-0.000,10.267 1.463,11.706 3.262,11.706 C4.270,11.706 5.172,11.254 5.770,10.545 L9.665,12.720 C9.544,13.055 9.478,13.416 9.478,13.792 C9.478,15.560 10.941,16.999 12.739,16.999 C14.537,16.999 16.000,15.560 16.000,13.792 C16.000,12.023 14.537,10.584 12.739,10.584 ZM12.739,1.121 C13.908,1.121 14.859,2.057 14.859,3.206 C14.859,4.356 13.908,5.292 12.739,5.292 C11.570,5.292 10.619,4.356 10.619,3.206 C10.619,2.057 11.570,1.121 12.739,1.121 ZM3.262,10.584 C2.092,10.584 1.141,9.648 1.141,8.499 C1.141,7.349 2.092,6.414 3.262,6.414 C4.431,6.414 5.382,7.349 5.382,8.499 C5.382,9.648 4.431,10.584 3.262,10.584 ZM12.739,15.876 C11.570,15.876 10.619,14.941 10.619,13.792 C10.619,12.642 11.570,11.706 12.739,11.706 C13.908,11.706 14.859,12.642 14.859,13.792 C14.859,14.941 13.908,15.876 12.739,15.876 Z"/></svg>');
	}
}

.popup-favorit{
	width: 175px;
	height: 74px;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.19);
	border-radius: 5px;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	position: absolute;
	top: 30px;
	left: 10px;
	z-index: 9999;
	.popup-favorit__title,
	.popup-favorit__remove-favorit{
		// width: 156px;
		height: 13px;
		color: #435269;
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 300;
		span{
			color: #1e7bc9;
			font-weight: 400;
			font-size: 13px;
		}
		span:hover{
	    color: #ff6163;
		}
	}
	.popup-favorit__remove-favorit{
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
	}
	.popup-favorit__meny{
		margin: 0;
		width: 103px;
		height: 24px;
		box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);
		border-radius: 3px;
		border: 1px solid #e1e1e1;
		background-color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		span{
			margin-left: 5px;
			color: #1e7bc9;
			font-weight: 400;
			font-size: 13px;
		}
	}
}