.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 18px;

  &__bar {
    display: block;
    width: 24px;
    height: 3px;
    background-color: $c-accent1;
    border-radius: 2px;
  }
}

.btn-close {
  position: fixed;
  top: 1.5em;
  right: 1em;
  height: 30px;

  &__bar {
    display: block;
    width: 28px;
    height: 3px;
    margin: auto;
    background-color: $c-accent1;
    transform-origin: center center;

    &:first-child {
      transform: rotate(45deg) translateY(2px);
    }
    &:last-child {
      transform: rotate(-45deg) translateY(-2px);
    }
  }
}