.popup-share {
  @extend .popup-sigin;
  max-height: 90vh;
  overflow-y: auto;
  position: fixed;

  @include bp($lg) {
    max-height: 600px;
    min-height: initial;
    overflow-y: initial;
  }
  
  .right {
    min-height: initial;
    flex-direction: column;
    align-items: center;
    padding: 100px 0 40px;
  }

  &__close {
    @extend .popup-exit;
  }

  &__title {
    font-size: 1.6rem;
  }

  &__socials {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
  }

  &__slide {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 70%, 100%;

    &:nth-child(1) {
      background-image: url(../img/popup-slider/химик.svg),
        linear-gradient(to top, $c-main, $c-accent2);
    }
    &:nth-child(2) {
      background-image: url(../img/popup-slider/учитель.svg),
        linear-gradient(to top, $c-main, $c-accent2);
    }
    &:nth-child(3) {
      background-image: url(../img/popup-slider/инженер.svg),
        linear-gradient(to top, $c-main, $c-accent2);
    }
  }

  form {
    margin: 2rem 0;

    @include bp($sm) {
      width: 300px;
    }
  }

  button {
    background: #b83539;

    &:hover {
      background: #d7212f;
    }
  }
}