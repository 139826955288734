.popup-sigin{
	// margin-top: 50px;
	position: absolute;
	left: 50%;
	top: 10%;
	transform: translateX(-50%);
	background-color: $c-main;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
	border: 1px solid rgba(0,0,0,.2);
	border-radius: 6px;
	width: 90%;
	max-height: 90vh;
	overflow-y: scroll;

	@include bp($lg) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 946px;
		overflow: visible;
		max-height: initial;
	}

	@include bp($xl) {
		min-height: 85vh;
	}
	

	.left{
		display: none;

		@include bp($lg) {
			display: block;
			margin: 0;
			height: 100%;

			background-color: rgb(29, 27, 16);
			border-radius: 6px 0px 0px 6px;
			overflow: hidden;
			user-select: none;
		}
	}

	.right {
		box-sizing: border-box;
		display: grid;
		justify-items: center;
		padding: 80px 15px 0;
		position: relative;
		width: 100%;
		border-radius: 0 6px 6px 0;

		@include bp($lg) {
			// grid-column: 3 / 4;
		}
		
		img {
			position: absolute;
			top: 36px;
			left: 50%;
			transform: translateX(-50%);
	    display: block;
	    width: 150px;
	    height: 27px;
		}

		&__span{
	    display: block;
	    max-width: 330px;
			margin: .5rem auto;

			font-size: 15px;
			font-family: 'Geometria-Light';
			line-height: 1.25;
			letter-spacing: 0.5px;
			text-align: center;
		}
		.signup{
			margin: 0 0 .5rem;

			color: #000;
			font-size: 14px;
			font-family: Geometria;
			letter-spacing: 2px;
			line-height: 2;
			text-align: center;
			text-transform: uppercase;

			&__list {
				position: relative;
				width: 100%;
				max-width: 300px;

				li {
					position: relative;
					display: block;
					border-radius: 20px;
					background-color: #5e88b9;
					margin: 0 auto 12px;
					cursor: pointer;
					// position: relative;
					transition: all .3s ease;

					a {
						display: block;
						padding-left: 58px;
						padding-right: 1rem;
						line-height: 3.2;

						color: #fff;
						font-family: Geometria;
						font-size: .8em;
						font-weight: 400;
						text-decoration: none;

						@include bp($sm) {
							font-size: 1em;
							line-height: 2.5;
						}
					}
				}
			}
		}
	}
		
	form{
		max-width: 330px;
		padding: 1rem 0;

		input{
			outline: none;
			width: 100%;
			line-height: 40px;
			color: #000;
			appearance: none;
			height: 41px;
			border-radius: 0px !important;
			font-size: 16px;
			box-sizing: border-box;
			border: 1px #e6e6e6 solid;
			background: #fff;
			padding: 0 50px 0 10px;
			margin-bottom: 15px;
		}
		input:active{
			border-color: #FFDF08;
		}
		input:focus{
			border-color: #FFDF08;
		}
	}

	button{
		transition: all 200ms ease;
		display: block;
		outline: none;
		border: none;
		width: 100%;
		height: 46px;
		padding: 11px 10px;
		margin: 0 0 50px;
		text-align: center;
		background: #89BF1D;
		font-size: 16px;
		color: #ffffff;
		border-radius: 4px;
		margin-bottom: 14px;
		
		&:hover{
			background-color: #6E9917;
			transition: all 200ms ease;
		}
	}
}


.soc-vk{
	&::before{
		content: '';
		position: absolute;
		left: 20px;
    top: 13px;
    width: 24px;
    height: 14px;
    background: url(https://cdn.megabonus.com/images/user/vk.svg) no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
	}
}
.soc-vk:hover{
  background: #4c7aaf !important;
}
.soc-ok{
  background: #e88504 !important;
	&::before{
		content: '';
		position: absolute;
    left: 27px;
    top: 8px;
    width: 14px;
    height: 23px;
    background: url(https://cdn.megabonus.com/images/user/ok.svg) no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
	}
}
.soc-ok:hover{
  background: #dc7c00 !important;
}
.soc-fb{
	background-color: #4a669f !important;
	&::before{
		content: '';
		position: absolute;
    left: 26px;
    top: 8px;
    width: 12px;
    height: 22px;
    background: url(https://cdn.megabonus.com/images/user/fb.svg) no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
	}
}
.soc-fb:hover{
  background: #3a558d !important;
}
.soc-google{
  background: #db5841 !important;

	&::before{
		content: '';
		position: absolute;
		left: 25px;
    top: 11px;
    width: 17px;
    height: 17px;
    background: url(https://cdn.megabonus.com/images/user/google.svg) no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
	}
}
.soc-google:hover{
  background: #b83539 !important;
}

.enter_email{
	// margin-top: -215px;
	text-align: center;
	width: 100%;
	font-size: 13px;
	font-family: Geometria;
	font-weight: 400;
	letter-spacing: 0.8px;
	color: #000;
	line-height: 2;
	margin-bottom: 15px;
	margin: 0;
	position: relative;

	&::before, &::after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    width: 20%;
		border-bottom: 2px dashed #dfdede;
		
		@include bp($sm) {
			width: 28%;
		}
		@include bp($xsm) {
			width: 35%;
		}
		@include bp($lg) {
			width: 30%;
			top: 44%;
		}
	}
	&::before {
    left: 0px;
	}

	&::after{
		right: 0px;
	}
}

.mini-btn-signup{
	display: block;
	font-size: 14px; 
	font-family: Geometria; 
	font-weight: 400; 
	line-height: unset !important; 
	text-align: unset !important; 
	width: unset !important; 
	height: unset !important;
	letter-spacing: 0.2px;
	color: #3498DB;
	text-decoration: none;
	margin-bottom: 15px;
}
.mini-btn-signup:hover{
	text-decoration: underline;
}

.signup-text{
	max-width: 370px;
	margin: 0;
	padding-bottom: 1rem;
	text-align: center;
	font-size: 12px; 
	font-family: Geometria; 
	font-weight: 400;
	color: #9B9B9B;
	a{
		color: #9B9B9B;
		text-decoration: none;
	}
	a:hover{
		text-decoration: underline;
	}
}

.popup-exit{
	position: absolute;
  top: 1rem;
  right: 1rem;
	background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px"><path fill="rgb(10,10,10)" d="M17.026,1.959 L9.964,9.022 L17.026,16.085 C17.284,16.342 17.284,16.761 17.026,17.020 C16.897,17.149 16.727,17.213 16.558,17.213 C16.389,17.213 16.219,17.149 16.090,17.020 L9.028,9.958 L1.966,17.020 C1.837,17.149 1.668,17.213 1.499,17.213 C1.329,17.213 1.160,17.149 1.031,17.020 C0.772,16.761 0.772,16.342 1.031,16.085 L8.093,9.022 L1.031,1.959 C0.772,1.702 0.772,1.282 1.031,1.025 C1.289,0.765 1.708,0.765 1.966,1.025 L9.028,8.087 L16.090,1.025 C16.348,0.765 16.767,0.765 17.026,1.025 C17.284,1.282 17.284,1.702 17.026,1.959 Z"/></svg>');
	background-size: cover;
  width: 16px;
  height: 16px;
	cursor: pointer;
	z-index: 90;

	@include bp($xl) {
		top: -2rem;
		right: -2rem;
		background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px"><path fill="rgb(255,255,255)" d="M17.026,1.959 L9.964,9.022 L17.026,16.085 C17.284,16.342 17.284,16.761 17.026,17.020 C16.897,17.149 16.727,17.213 16.558,17.213 C16.389,17.213 16.219,17.149 16.090,17.020 L9.028,9.958 L1.966,17.020 C1.837,17.149 1.668,17.213 1.499,17.213 C1.329,17.213 1.160,17.149 1.031,17.020 C0.772,16.761 0.772,16.342 1.031,16.085 L8.093,9.022 L1.031,1.959 C0.772,1.702 0.772,1.282 1.031,1.025 C1.289,0.765 1.708,0.765 1.966,1.025 L9.028,8.087 L16.090,1.025 C16.348,0.765 16.767,0.765 17.026,1.025 C17.284,1.282 17.284,1.702 17.026,1.959 Z"/></svg>');
	}
}


.popup-bg{
	display: block;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
  background: rgba(0, 0, 0, 0.53);
}

.title-parthers{
  display: block;
  margin: 0 0 20px;
  text-align: center;
  font-size: 16px;
	font-family: Geometria; 
	font-weight: 400;
}

.parthners-list{
  width: 430px;
  list-style: none;
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 0;
  margin: 0 auto;
	li{
		display: inline-block;
		vertical-align: middle;
		margin: 0 0 10px 10px;
	}
}

.slider-popup-wrapp{
  position: relative;
  height: 100%;
}

.swiper-container {
  width: 100%;
	height: 100%;
	background-color: #fede31;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.popup-sigin__slide, .popup-login__slide {	
	margin-right: 0;
	padding-top: 40px;
	background-repeat: no-repeat;
	background-size: cover;

	&:nth-child(1) {
		background-image: url('../img/popup-slider/icon-slider-1-test1.png');
	}
	&:nth-child(2) {
		background-image: url('../img/popup-slider/slider-2-bg.png');
	}
	&:nth-child(3) {
		background-image: url('../img/popup-slider/slider-3-bg.png');
	}
	&:nth-child(4) {
		background-image: url('../img/popup-slider/slider-4-bg.png');
	}
	&:nth-child(5) {
		background-image: url('../img/popup-slider/slider-5-bg.png');
	}
	&:nth-child(6) {
		background-image: url('../img/popup-slider/slider-6-bg.png');
	}
	&:nth-child(7) {
		background-image: url('../img/popup-slider/icon-slider-1-test1.png');
	}
}

.h2-slider{
	width: 286px;
	color: #000;
	font-size: 24px;
	line-height: 1.4;
	text-transform: uppercase;
}
.span-slider{
	width: 349px;
	height: 29px;
	opacity: 0.9;
	font-family: "Open Sans";
	font-size: 13px;
	color: #000;
	position: absolute;
	top: 140px;
	a{
		font-family: "Open Sans";
		color: #1e6cb4;
		text-decoration: none;
		outline: none;
		border-bottom: 1px solid #1e6cb4;
	}
}

.slider-swiper-text{
	margin-top: -100px;
}

.swiper-pagination-bullet-active{
	background-color: #000 !important;
}

.swiper-pagination-bullet{
	background-color: $c-secondary;
}

.popup-sigin{
	transition: all .5s ease;
}

.login-wrapper-btn{
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include bp($xs) {
		flex-direction: row;
	}
}