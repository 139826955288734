.aside {

  @include bp($lg) {
    width: 355px;
  }

  &-banner__canvas {
    display: none !important;

    @include bp($lg) {
      display: none !important;
    }
  }

  &-list{
    margin-bottom: 20px;
    padding: 0 15px;

    @include bp($xsm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 1rem;
    }

    @include bp($lg) {
      display: block;
    }

    // .aside-list
    &__item{
      display: flex;
      align-items: flex-start;
      // width: 297px;
      margin-bottom: 1rem;
    
      a {
        display: block;
        width: 100%;
        color: #435269;
        margin-bottom: 5px;
        text-decoration: none;
      }
    }

    // .aside-list
    &__text {
      margin: 0;
      // width: 216px;
      margin-left: 10px;
      color: #435269;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;

      @include bp($xs) {
        font-size: .9rem;
      }
    }
  }

  &__title{
    color: #435269;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    margin: 0 15px 18px;
  }

  &-search {
    // width: 335px;
    border-top: 1px solid rgba($c-secondary, .2);
    padding: 1rem 15px 0;

    &__link {
      color: #619f00;
      font-size: 14px;
      font-weight: 400;
      display: block;
      margin-bottom: 17px;
      text-decoration: none;

      &:hover{
        color: #488416;
        transition: all 200ms ease;
      }
    }

    &__title {
      margin: 0 0 1rem;
    }
  }
}

.more-story{
  display: inline-block;
  margin: 0 15px 1rem;

	color: #619f00;
	font-size: 14px;
	text-decoration: none;
	transition: all 200ms ease;
  
  &:hover {
    color: #488416;
    transition: all 200ms ease;
  }
}

.stats {
  &-wrapper{
    border-top: 1px solid rgba($c-secondary, .2);
    margin-bottom: 1rem;
    padding: 1rem 15px 0;

    @include bp($xsm) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 2rem;
    }

    @include bp($lg) {
      grid-template-columns: 1fr;
    }

    img {
      display: block;
      margin: 0 auto 30px;

      @include bp($xsm) {
        margin: 0;
      }
    }
  }

  &-list{
    align-self: center;
    list-style: none;
    padding: 0;
    margin: 0;
    // width: 195px;
    margin-bottom: 20px;

    @include bp($sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(4, 2rem);
      grid-auto-flow: column;
    }

    @include bp($lg) {
      display: block;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
      
      &--box {
        width: 14px;
        height: 14px;
        background-color: grey;
        margin-right: 11px;

        &.blue{
          background-color: #7cb5ec;
        }
        &.french-lilac {
          background-color: #E9A9EE;
        }
        &.orange{
          background-color: #f7a35c;
        }
        &.red{
          background-color: #ff0066;
        }
        &.screaming-green {
          background-color: #43ef77;
        }
        &.spray {
          background-color: #84EFEF;
        }
      }

      span {
        color: #2e3e55;
        font-family: "Open Sans";
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
  
  &-link {
    grid-column: 1 / -1;
    color: #619f00;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: none;
    transition: all 200ms ease;

    &:hover {
      color: #488416;
      transition: all 200ms ease;
    }
  }

  &__title {

    @include bp($xsm) {
      grid-column: 1 / -1;
      margin-bottom: 0;
    }
  }
}
