.cookie {
  display: none;

  @include bp($xl) {
    display: block;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    padding: 0 1rem;
    width: 100%;
  
    // to overflow header
    z-index: 11;
    
    background-color: rgba($c-secondary, .8);
    color: $c-main;
    text-align: center;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    margin: 0 auto;
    padding: 0 15px;
    width: 1170px;
  }

  &__button {
    margin: 0 1rem;
    padding: .5rem 1rem;
    background-color: #8bc72a;
    border: 0;
    border-radius: 2px;
    color: $c-main;
    font-family: $ff-main;
    text-transform: uppercase;
    transition: .2s;

    &:hover {
      background-color: #619f00;
    }
  }

  &__link {
    color: $c-main;
    text-decoration: none;
    transition: .2s;
    white-space: nowrap;

    &:hover {
      color: #8bc72a;
      text-decoration: underline;
    }
  }
}