.footer{
	margin-top: 50px;
	width: 100%;
	background-color: #f5f7f7;
	box-sizing: border-box;
	border-top: 3px solid #7c8797;

	&_stick {
		margin-top: 0;
	}

	a:hover{
		text-decoration: underline;
	}
}

.footer-wrapper{
	box-sizing: border-box;
	padding-top: 30px;
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;
	min-height: 276px;

	display: grid;
	grid-gap: 0 2rem;

	@include bp($lg) {
		grid-template-columns: repeat(3, 1fr) auto;
		max-width: 1200px;
	}
}

.footer-list{
	margin-bottom: 1rem;

	&__title{
		margin: 0;
		margin-bottom: 20px;
		height: 13px;
		color: #435269;
		font-family: "Open Sans";
		font-size: 18px;
		font-weight: 400;
		line-height: 18px;
	}
	
	&__item{
		display: inline-block;
		margin-right: .5rem;
		margin-bottom: .5rem;

		@include bp($lg) {
			display: block;
		}

		a {
			color: #2e3e55;
			font-family: "Open Sans";
			font-size: 13px;
			font-weight: 400;
			text-decoration: none;
		}
	}
	
}


.wrapper-footer__block {
	// width: 344px;
	grid-row: 1 / 2;
	margin-bottom: 1rem;

	@include bp($xs) {
		width: 80%;
	}
	@include bp($sm) {
		width: 300px;
	}
	@include bp($lg) {
		grid-row: initial;
	}

	b {
		margin: 0;
		margin-bottom: 25px;
		height: 13px;
		color: #435269;
		font-family: "Open Sans";
		font-size: 18px;
		font-weight: 400;
		line-height: 18px;
		display: block;
	}
	
  img{
		cursor: pointer;
		width: 100%;
	}
}