
.filter-wrapper{
	align-items: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;

	@include bp($lg) {
		max-width: 1200px;
	}

	h1 {
		margin-bottom: .5rem;
		color: $c-main;
		font-family: "Geometria-ExtraBold";
		font-size: .95rem;
		text-align: center;
		text-transform: uppercase;

		@include bp($sm) {
			font-size: 1.5rem;
		}
		@include bp($md) {
			font-size: 2rem;
		}
		@include bp($lg) {
			font-size: 2.25rem;
		}
	}

	h2 {
		margin-bottom: 22px;
		color: $c-accent1;
		font-family: "Geometria-ExtraBold";
		text-align: center;

		@include bp($sm) {
			font-size: 1.5rem;
		}
		@include bp($md) {
			font-size: 2rem;
		}
		@include bp($lg) {
			font-size: 2.25rem;
		}
	}
}

.filter__title-btn{
	display: flex;
	width: 100%;

	@include bp($lg) {
		width: 900px;
	}
}

.filter-btn{
	outline: none;
	cursor: pointer;
	border: none;
	// width: 300px;
	height: 50px;
	background-color: $c-main;
	border-radius: 0 5px 5px 0;
	color: $c-secondary;
	font-family: Geometria, Arial, sans-serif;
	font-weight: 400;	
	flex: 1;

	@include bp($md) {
		height: 60px;
		font-size: 18px;
	}
	@include bp($lg) {
		height: 60px;
	}
}

.fist-filt-btn{
	border-radius: 5px 0 0 5px;
}

.filter__btn--center{
	border-radius: unset;
	position: relative;
	.border1{
		content: '';
		display: block;
		width: 1px;
		height: 29px;
		background-color: #cbcbcb;
		opacity: 0.5;
		position: absolute;
		left: 1px;
		top: 50%;
		transform: translateY(-50%);
	}
	.border2{
		content: '';
		display: block;
		width: 1px;
		height: 29px;
		background-color: #cbcbcb;
		opacity: 0.5;
		position: absolute;
		right: 1px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.filter__btn--active{
	box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.43);
	color: $c-main;
	background-color: $c-accent1;
}

// search(1)

.filter-block{
	margin-top: 30px;
	// background-color: grey;
	position: relative;
	// transition: all 1s ease;
	p{
		margin-bottom: 0;
		margin-top: 0;
	}
}

.search-workers{
	width: 100%;
}
.filter-form {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	@include bp($lg) {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-gap: 2rem 1rem;
		align-items: end;
	}
}

.filter-form__item{
	color: $c-secondary;
	font-family: Geometria;
	font-size: 18px;
	font-weight: 400;
	// width: 365px;
	position: relative;
	width: 100%;
	overflow: hidden;
	resize: none;

	@include bp($lg) {
		grid-column: span 4;
	}

	> label {
		display: block;
		margin-bottom: .5rem;
		font-size: .9rem;

		@include bp($sm) {
			font-size: 1rem;
		}
		@include bp($md) {
			font-size: 1.2rem;
		}
	}

	input[type="text"] {
		display: inline-block;
		border: none;
		outline: none;
		width: 100%;
		height: 40px;
		border-radius: 5px;
		background-color: $c-main;
		color: $c-secondary;
		font-family: 'Geometria', Arial, sans-serif;
		font-size: 1rem;
		font-weight: 300;
		box-sizing: border-box;
		padding-left: 21px;
		margin-bottom: 1rem;
		z-index: 2;
		position: relative;
		overflow: hidden;
		resize: none;

		@include bp($xsm) {
			margin-bottom: 1.5rem;
		}
		@include bp($md) {
			height: 60px;
			font-size: 1.1rem;
		}
		@include bp($lg) {
			margin-bottom: 0;
		}
	}
}

.filter-form__select-wrapper{
	// width: 753px;
	// display: flex;
	width: 100%;
	// align-items: center;
	justify-content: space-between;
	// margin-bottom: 1rem;

	@include bp($lg) {
		grid-column: span 6;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 0 1rem;
	}

	&_wide {
		@include bp($lg) {
			grid-column: span 8;
		}
	}

}



.filter-form__select-item{
	// width: 237px;
	label {
		display: block;
		color: $c-secondary;
		font-family: Geometria;
		// font-size: 18px;
		font-size: .9rem;
		font-weight: 400;
		margin-bottom: .5rem;

		@include bp($sm) {
			font-size: 1rem;
		}
		@include bp($md) {
			font-size: 1.2rem;
		}
	}
	select {
		box-sizing: border-box;
		display: inline-block;
		width: 100%;
		border: none;
		outline: none;
		height: 40px;
		margin-bottom: 1rem;
		padding-left: 21px;

		border-radius: 5px;
		background-color: $c-main;
		color: $c-secondary;
		font-family: 'Geometria-Light', Arial, sans-serif;
		font-size: 1em;
		font-weight: 300;

		background-image: url('../img/select_down.png');
		background-repeat: no-repeat;
		background-position: 95% center;

		position: relative;

		@include bp($md) {
			height: 60px;
			font-size: 1.2rem;
		}

		@include bp($lg) {
			margin-bottom: 0;
		}

		&::after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			background-color: red;
			width: 10px;
			height: 5px;
		}
	}
}
// .filter-form__item-4{
// 	margin-right: 20px;
// }


.filter-form__slider-wrapper{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@include bp($xsm) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 0 3rem;
	}

	@include bp($lg) {
		grid-column: 1 / -1;
		grid-gap: 0 5rem;
	}
}

.slider{
	// width: 525px;
	width: 100%;
	height: 58px;
	margin-bottom: 40px;

	@include bp($xsm) {
		&-1 {
			grid-column: 1 / 3
		}		
		&-2 {
			grid-column: 1 / 2;
			grid-row: 3 / 4;
		}
		&-3 {
			grid-column: 1 / 3;
			grid-row: 2 / 3;
		}
	}

	@include bp($xl) {
		&-1 {
			grid-column: 1 / 2;
		}
		&-2 {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
		&-3 {
			grid-column: span 1;
			grid-row: 2 / 3;
		}
	}

	&-2 {
		justify-self: end;
	}

	span {
		color: $c-secondary;
		font-family: Geometria, Arial, sans-serif;
		font-size: .9rem;
		font-weight: 400;
		display: block;
		width: 100%;
		margin-bottom: 45px;

		@include bp($sm) {
			font-size: 1rem;
		}
		@include bp($md) {
			font-size: 1.2rem;
		}
		@include bp($lg) {
			margin-bottom: 50px;
		}
	}
	.slider-body{
		position: relative;
		/* Style for "Прямоуголь" */
		width: 555px;
		height: 6px;
		border-radius: 3px;
		background-color: $c-main;
	}
	.slider-wrapp {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		background-color: $c-accent1;	
		position: relative;
		margin-left: 0;
	}
}



.wrapper-chekbox{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 2rem;

	span{
		width: 100%;
		color: $c-secondary;
		font-family: Geometria, Arial, sans-serif;
		font-weight: 400;
		display: block;
		margin-bottom: 2rem;

		@include bp($xsm) {
			// for align with range slider
			margin-bottom: 40px;
		}

		@include bp($md) {
			font-size: 1.2rem;
		}
	}
	.first-checkbox {
    margin-right: 45px;
	}
}

.chekbox-item {
	position: relative;
	max-height: 22px;

	label {
		width: 99px;
		color: #2b2b2b;
		font-family: Geometria, Arial, sans-serif;
		font-weight: 400;
		box-sizing: border-box;
		padding-left: 9px;
		cursor: pointer;

		@include bp($md) {
			font-size: 1.2rem;
		}

		&::after{
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 22px;
			height: 22px;
			border-radius: 3px;
			background-color: $c-main;
			box-sizing: border-box;
		}
	}

	input:checked + label::after{
		box-sizing: border-box;
		border: 5px solid $c-main;
		border-radius: 3px;
		background-color: $c-accent1;
	}
}

.form__btn-wrapper{
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 40px;

	@include bp($lg) {
		grid-column: 5 / span 4;
	}
}

.form-submit{
	border: none;
	outline: none;
	width: 100%;
	height: 40px;
	border-radius: 5px;
	font-family: Geometria, Arial, sans-serif;
	font-weight: 400;

	background-color: $c-main;
	color: $c-secondary;
	cursor: pointer;
	transition: all 300ms ease;

	@include bp($md) {
		height: 55px;
		font-size: 1.2rem;
	}
}

.form-submit:hover{
	transition: all 300ms ease;
	background-color: $c-accent1;
	color: $c-main;
}

.slider-wrapp{
	position: relative;
}

.min-btn > button{
	cursor: pointer;
	position: absolute;
	top: 50%;
	left: -10px;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
	background-image: url('../img/min-slider-item.png');
	background-position: center center;
	background-repeat: no-repeat;
	border: none;
	outline: none;
	background-color: unset;	
}

.min-btn > input{
	position: absolute;
	width: 42px;
	height: 18px;
	color: #2b2b2b;
	font-family: Geometria;
	font-size: 18px;
	font-weight: 400;
	border: none;
	top: -33px;
	left: -10px;
	background-color: unset;
}

.big-btn > button{
	position: absolute;
	cursor: pointer;
	top: 50%;
	right: -20px;
	transform: translateY(-50%);
	width: 50px;
	height: 50px;
	background-image: url('../img/big-slider-item.png');
	background-position: center center;
	background-repeat: no-repeat;
	border: none;
	outline: none;
	background-color: unset;
}

.big-btn > input{
	position: absolute;
	width: 42px;
	height: 18px;
	color: #2b2b2b;
	font-family: Geometria;
	font-size: 18px;
	font-weight: 400;
	border: none;
	top: -33px;
	right: -27px;
	background-color: unset;
}

.range {
  width: 100%;
	height: 6px;
	border-radius: 3px;
	background-color: $c-main;
  position: relative;
}

.range__button_1,
.range__button_2 {
    position: absolute;
    top: 50%;

    width: 20px;
    height: 20px;

    cursor: move;
    transform: translateY(-50%);

    border: none;
    border-radius: 50%;
    background-color: #999;
}

.range__between{
  position: absolute;
  height: 100%;
  width:0px;
  border: none;
	border-radius: 3px 0 0 3px;
	background-color: $c-accent1;
}

.range_inpt1,
.range_inpt2{
  margin-top:20px;
  width: 50px;
}

.range__button_1{
	width: 8px;
	height: 8px;
	position: absolute;
	background-color: $c-accent1;
}

.range__button_2{
	width: 8px;
	height: 8px;
	position: absolute;
	background-color: rgba(0,0,0,0);
}

.btn-over{
	width: 20px;
	height: 20px;
	// opacity: 0.5;
	background-color: #cf6b27;
	position: relative;
	z-index: 99;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	box-shadow: unset;
	cursor: pointer;
	transition: .2s;
}

.btn-over-after{
	content: '';
	display: block;
	width: 8px;
	height: 8px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background-color: $c-accent1;	
	transition: all 0.3s ease;
}

.btn-over:hover{
	box-shadow: 0px 0px 0px 9px rgba(184, 53, 57, 0.16);
}

.range_inpt1,
.range_inpt2{
	margin-top: 0;
	position: absolute;
	top: -180%; 
	left: 0px;
	background-color: unset;
	border: none;
	color: #2b2b2b;
	font-family: Geometria;
	font-size: 18px;
	font-weight: 400;
}

.range_inpt1--cash{
	width: 75px;
	text-align: center;
	left: -30%;
	transform: translateX(-30%);
}

.range_inpt2--cash{
	width: 75px;
	text-align: center;
	left: -30%;
	transform: translateX(-30%);
}

.slider-1-2{
	width: 100%;

	@include bp($lg) {
		grid-column: span 2;
	}
}

.filter-form__item-3-4{
	@include bp($lg) {
		grid-column: span 6;
	}
}

.select-item-3{
	grid-column: span 3;
}

.btn-over-1,
.btn-over-2{
	transition: opacity 0.5s ease;
}


.inputBorderBottom{
	transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
	position: absolute;
	box-sizing: border-box;
	top: 80%;
	width: 0%;
	margin-left: 50%;
	margin-right: 50%;
	height: 4px;
	border-radius: 50px;
	background-color: $c-main;
	z-index: 2;

	@include bp($lg) {
		top: initial;
		bottom: 0;
	}
}