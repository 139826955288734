// page-navigation
.page-navigation{
	width: 100%;
	min-height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include bp($lg) {
		justify-content: flex-end;
	}

  &__btn {
    border: none;
    outline: none;
    background-color: unset;

    &--min {
      border-radius: 2px;
      border: 1px solid #bec3cb;
      background-image: url('../img/arrow.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 9px 18px;
      width: 45px;
      height: 45px;
    }

    &--big {
      width: 70px;
      height: 45px;
      border-radius: 2px;
      border: 1px solid #619f00;
      background-image: url('../img/arrow-right-green.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 9px 18px;
      transition: all 200ms ease;
      cursor: pointer;

      &:hover {
        color: $c-main;
        border-color: #619f00;
        background-color: #619f00;
        background-image: url('../img/arrow-left-white.png');
			}
			
			@media screen and (min-width: 320px) {
				width: 90px;
			}
    }
  }

  &-num {
		width: 56px;
		
    span{
      color: #2e3e55;
      font-family: $ff-main;
      font-size: 13px;
      font-weight: $fw-r;
    }
  }

	.section-1 {
		width: 100%;
		height: 45px;
		margin: 0 15px;

		display: grid;
		grid-gap: 0 1rem;
		grid-template-columns: 45px 45px 1fr auto;
		text-align: center;

		@include bp($xsm) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 271px;
		}
	}
	.section-2 {
		display: none;

		@include bp($xsm) {
			display: flex;
			width: 228px;
			height: 59px;
			border-radius: 2px;
			background-color: #f5f7f7;
			box-sizing: border-box;
			margin: 0 15px;
			padding: 15px;
			justify-content: space-between;
			align-items: center;
		}
    
		span {
			display: block;
			width: 74px;
			height: 29px;
			color: #2e3e55;
			font-family: $ff-main;
			font-size: 13px;
			font-weight: $fw-r;
    }
    
		input {
			border: none;
			outline: none;
			width: 52px;
			height: 45px;
			border-radius: 2px;
			background-color: $c-main;
			// opacity: 0.6;
			color: #2e3e55;
			font-family: $ff-main;
			font-size: 13px;
			font-weight: $fw-r;
			box-sizing: border-box;
			padding-left: 15px;
		}
		button {
			width: 51px;
			// border: none;
			outline: none;
			height: 45px;
			border-radius: 2px;
			border: 1px solid #bec3cb;
			color: #2e3e55;
			font-family: $ff-main;
			font-size: 16px;
			font-weight: $fw-r;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}