// Palette
$c-main: #fff;
$c-secondary: #2c2c2c;
$c-accent1: #d7212f;
$c-accent2: #fede31;
$c-accent3: #f2fbf0;

// font
$ff-main: 'Open Sans', sans-serif;

$fw-l: 300;
$fw-r: 400;
$fw-b: 800;

// breakpoints
$xs: 360px;
$sm: 480px;
$xsm: 640px;
$md: 736px;
$lg: 960px;
$xl: 1280px;
$xxl: 1680px;

