@font-face {
  font-family: 'Geometria';
  src: url('../fonts/Geometria.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria-Light';
  src: url('../fonts/Geometria-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria-ExtraBold';
  src: url('../fonts/Geometria-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}